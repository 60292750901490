<template>
  <div>
    <base-page
      title="Insurance product"
      ctaText="Add product"
      :breadcrumbs="breadcrumbs"
      @action="dialog = true"
    >
      <template #content>
        <product-list
          @edit="edit"
        />
      </template>
    </base-page>

    <product-form
      v-if="dialog"
      :dialog="dialog"
      :product="product"
      @close="close()"
    ></product-form>
  </div>
</template>

<script>
export default {
  components: {
    'product-list': () => import('@/views/insurance/ProductList.vue'),
    'product-form': () => import('@/views/insurance/ProductForm.vue'),
  },

  data () {
    return {
      dialog: false,
      product: null,
      breadcrumbs: [
        { text: 'Products', disabled: true, },
      ],
    }
  },

  methods: {
    close () {
      this.dialog = false
    },

    edit (product) {
      this.product = product
      this.dialog = true
    }
  }
} 
</script>